import { desktopPlacements } from './desktop';
import { tabletPlacements } from './tablet';
import { mobilePlacements } from './mobile';

const advertoryPlacements = {
    desktop: desktopPlacements,
    tablet: tabletPlacements,
    mobile: mobilePlacements,
};

export { advertoryPlacements };
