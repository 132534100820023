export const getNode = (targetId) => document.getElementById(targetId);

const CONFIG_STORE = new Map();
export const getTagConfig = (targetId) => {
    if (CONFIG_STORE.has(targetId)) {
        return CONFIG_STORE.get(targetId);
    }

    try {
        const config = JSON.parse(
            getNode(`${targetId}-tag`)?.textContent ?? '{}',
        );

        CONFIG_STORE.set(targetId, config);

        return config;
    } catch (err) {
        console.error(err);
        return {};
    }
};
