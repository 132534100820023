import { prepareAdvertoryConfig } from './config';
import { initAdvertoryPackage } from '@sch-inventory/advertory/core/vg';
import { advertRefresher } from '../advert/advert-refresher';

const initAdvertory = async () => {
    await initAdvertoryPackage(await prepareAdvertoryConfig());
    // FIXME: Contact Mads for details if you wish to port it :)
    advertRefresher();
};

// @ts-ignore
export default () => initAdvertory();
