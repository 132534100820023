const desktopPlacements = [
    {
        id: 'topboard',
        sizes: [
            [980, 150],
            [1000, 150],
            [1010, 150],
            [980, 300],
            [1010, 300],
            [728, 90],
            [928, 120],
            [980, 240],
        ],
        invCode: 'no-vg-wde-front_topboard',
        allowedFormats: ['banner'],
        keywords: {
            'no-sno-adformat': ['topboard'],
        },
    },
    {
        id: 'skyscraperleft_1',
        sizes: [
            [180, 701],
            [180, 501],
        ],
        invCode: 'no-vg-wde-front_skyscraperleft_1',
        allowedFormats: ['banner'],
        keywords: {
            'no-sno-adformat': ['skyscraperleft_1'],
        },
        minViewWidth: 1399,
    },
    {
        id: 'skyscraperright_1',
        sizes: [
            [160, 600],
            [180, 500],
            [180, 700],
            [300, 600],
        ],
        invCode: 'no-vg-wde-front_skyscraperright_1',
        allowedFormats: ['banner'],
        keywords: {
            'no-sno-adformat': ['skyscraperright_1'],
        },
        minViewWidth: 1399,
    },
    {
        id: 'wallpaper',
        sizes: [[2, 2]],
        invCode: 'no-vg-wde-front_wallpaper',
        allowedFormats: ['banner'],
        keywords: {
            'no-sno-adformat': ['wallpaper'],
        },
        minViewWidth: 1399,
    },
    {
        id: 'backfill_1',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        invCode: 'no-vg-wde-front_netboard_backfill_1',
        allowedFormats: ['banner', 'native'],
        keywords: {
            'no-sno-adformat': ['netboard_backfill_1'],
        },
        finnBlink: {
            enabled: true,
            position: 'hayabusa_box_2',
        },
    },
    {
        id: 'backfill_2',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        invCode: 'no-vg-wde-front_netboard_backfill_2',
        allowedFormats: ['banner', 'native'],
        keywords: {
            'no-sno-adformat': ['netboard_backfill_2'],
        },
        finnBlink: {
            enabled: true,
            position: 'hayabusa_box_3',
        },
    },
    {
        id: 'netboard_1',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        invCode: 'no-vg-wde-front_netboard_1',
        threshold: 100,
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['netboard_1'],
        },
        finnBlink: {
            enabled: true,
            position: 'hayabusa_box_1',
            renderOnlyWithAdBlock: true,
        },
        nativeCustomProperties: ['video'],
    },
    {
        id: 'netboard_2',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        invCode: 'no-vg-wde-front_netboard_2',
        allowedFormats: ['banner', 'video'],
        keywords: {
            'no-sno-adformat': ['netboard_2'],
        },
        finnBlink: {
            enabled: true,
            position: 'netboard2',
        },
    },
    {
        id: 'netboard_3',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        invCode: 'no-vg-wde-front_netboard_3',
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['netboard_3'],
        },
        finnBlink: {
            enabled: true,
            position: 'netboard3',
        },
    },
    {
        id: 'netboard_4',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        invCode: 'no-vg-wde-front_netboard_4',
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['netboard_4'],
        },
        finnBlink: {
            enabled: true,
            position: 'netboard4',
        },
    },
    {
        id: 'netboard_5',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        invCode: 'no-vg-wde-front_netboard_5',
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['netboard_5'],
        },
    },
    {
        id: 'netboard_6',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        invCode: 'no-vg-wde-front_netboard_6',
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['netboard_6'],
        },
    },
    {
        id: 'backfill_3',
        sizes: [
            [980, 600],
            [980, 500],
        ],
        invCode: 'no-vg-wde-front_brandboard_backfill_1',
        allowedFormats: ['banner', 'native'],
        keywords: {
            'no-sno-adformat': ['brandboard_backfill_1'],
        },
        finnBlink: {
            enabled: true,
            position: 'brandboard_backfill_1',
        },
    },
    {
        id: 'backfill_4',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        invCode: 'no-vg-wde-front_netboard_backfill_4',
        allowedFormats: ['banner', 'native'],
        keywords: {
            'no-sno-adformat': ['netboard_backfill_4'],
        },
    },
    {
        id: 'brandboard',
        sizes: [
            [980, 300],
            [980, 500],
            [980, 600],
            [1920, 1080],
        ],
        invCode: 'no-vg-wde-front_brandboard',
        allowedFormats: ['banner'],
        keywords: {
            'no-sno-adformat': ['brandboard', 'fullscreen'],
        },
        finnBlink: {
            enabled: true,
            position: 'advert-brandboard',
        },
    },
    {
        id: 'netboard_7',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        invCode: 'no-vg-wde-front_netboard_7',
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['netboard_7'],
        },
    },
    {
        id: 'netboard_8',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        invCode: 'no-vg-wde-front_netboard_8',
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['netboard_8'],
        },
    },
    {
        id: 'netboard_9',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        invCode: 'no-vg-wde-front_netboard_9',
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['netboard_9'],
        },
    },
    {
        id: 'netboard_10',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        invCode: 'no-vg-wde-front_netboard_10',
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['netboard_10'],
        },
    },
    {
        id: 'netboard_11',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        invCode: 'no-vg-wde-front_netboard_11',
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['netboard_11'],
        },
    },
    {
        id: 'test_1',
        sizes: [[1, 2]],
        invCode: 'no-vg-wde-front_test_1',
        allowedFormats: ['banner'],
        keywords: {
            'no-sno-adformat': ['test_1'],
        },
    },
];

export { desktopPlacements };
