const tabletPlacements = [
    {
        id: 'topboard',
        sizes: [
            [980, 150],
            [1000, 150],
            [1010, 150],
            [980, 300],
            [1010, 300],
            [728, 90],
            [928, 120],
            [980, 240],
        ],
        invCode: 'no-vg-wtb-front_topboard',
        threshold: 250,
        allowedFormats: ['banner', 'video'],
        keywords: {
            'no-sno-adformat': ['topboard'],
        },
    },
    {
        id: 'netboard_1',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        invCode: 'no-vg-wtb-front_netboard_1',
        threshold: 200,
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['netboard_1'],
        },
        finnBlink: {
            enabled: true,
            position: 'hayabusa_box_1',
        },
        nativeCustomProperties: ['video'],
    },
    {
        id: 'netboard_2',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        invCode: 'no-vg-wtb-front_netboard_2',
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['netboard_2'],
        },
        finnBlink: {
            enabled: true,
            position: ['netboard2', 'hayabusa_box_4'],
        },
    },
    {
        id: 'netboard_3',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        invCode: 'no-vg-wtb-front_netboard_3',
        threshold: 250,
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['netboard_3'],
        },
        finnBlink: {
            enabled: true,
            position: 'netboard3',
        },
    },
    {
        id: 'netboard_4',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        invCode: 'no-vg-wtb-front_netboard_4',
        threshold: 200,
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['netboard_4'],
        },
        finnBlink: {
            enabled: true,
            position: 'netboard4',
        },
    },
    {
        id: 'netboard_5',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        invCode: 'no-vg-wtb-front_netboard_5',
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['netboard_5'],
        },
    },
    {
        id: 'netboard_6',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        invCode: 'no-vg-wtb-front_netboard_6',
        threshold: 200,
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['netboard_6'],
        },
    },
    {
        id: 'netboard_7',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        invCode: 'no-vg-wtb-front_netboard_7',
        threshold: 200,
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['netboard_7'],
        },
    },
    {
        id: 'netboard_8',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        invCode: 'no-vg-wtb-front_netboard_8',
        threshold: 200,
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['netboard_8'],
        },
    },
    {
        id: 'netboard_9',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        invCode: 'no-vg-wtb-front_netboard_9',
        threshold: 200,
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['netboard_9'],
        },
    },
    {
        id: 'netboard_10',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        invCode: 'no-vg-wtb-front_netboard_10',
        threshold: 200,
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['netboard_10'],
        },
    },
    {
        id: 'netboard_11',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        invCode: 'no-vg-wtb-front_netboard_11',
        threshold: 200,
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['netboard_11'],
        },
    },
    {
        id: 'test_1',
        sizes: [[1, 2]],
        invCode: 'no-vg-wtb-front_test_1',
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['test_1'],
        },
    },
    {
        id: 'backfill_1',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        invCode: 'no-vg-wtb-front_netboard_backfill_1',
        allowedFormats: ['banner', 'native'],
        keywords: {
            'no-sno-adformat': ['netboard_backfill_1'],
        },
        finnBlink: {
            enabled: true,
            position: 'hayabusa_box_2',
        },
    },
    {
        id: 'backfill_2',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        invCode: 'no-vg-wtb-front_netboard_backfill_2',
        allowedFormats: ['banner', 'native'],
        keywords: {
            'no-sno-adformat': ['netboard_backfill_2'],
        },
        finnBlink: {
            enabled: true,
            position: 'hayabusa_box_3',
        },
    },
    {
        id: 'backfill_3',
        sizes: [
            [980, 600],
            [980, 500],
        ],
        invCode: 'no-vg-wtb-front_netboard_backfill_3',
        allowedFormats: ['banner', 'native'],
        keywords: {
            'no-sno-adformat': ['netboard_backfill_3'],
        },
    },
    {
        id: 'backfill_4',
        sizes: [
            [580, 400],
            [580, 500],
            [1, 1],
        ],
        invCode: 'no-vg-wtb-front_netboard_backfill_4',
        allowedFormats: ['banner', 'native'],
        keywords: {
            'no-sno-adformat': ['netboard_backfill_4'],
        },
    },
];

export { tabletPlacements };
